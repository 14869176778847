@charset 'utf-8';

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        @for $i from 0 through 70 {
            .u-fs#{$infix}-#{$i} {
                font-size: rem($i) !important;
            }
        }
    }
}

.u-text {
  &-color {
    &--primary {
      color: $primary;
    }
    &--secondary {
      color: $secondary;
    }
    &--danger {
      color: $danger;
    }
    &--gray {
      color: #999;
    }
  }
  &--normal {
    font-weight: normal;
  }
  &--bold {
    font-weight: bold;
  }
  &--left {
    text-align: left;
  }
  &--right {
    text-align: right;
  }
  &--center {
    text-align: center;
  }
}
