@charset 'utf-8';

.select2-container {
  z-index: 1;
}

body > .select2-container {
  z-index: 9999;
}

.select2-container--default .select2-selection--single {
  border-color: #ddd;
  border-radius: 0;
  height: calc(2.75rem + 2px);
  padding: .5rem 0;
}
.select2-container--default .select2-selection--multiple {
  border-color: #ddd;
  border-radius: 0;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: calc(2.75rem + 2px);
}

.select2-container .select2-selection--single .select2-selection__rendered,
.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding-left: rem(10);
  padding-right: rem(10);
}

.dropify-wrapper:hover {
  background-repeat: repeat;
}


.js-datepicker,
.js-timepicker,
.js-datetimepicker {
  .c-input-group-append__item,
  .c-input-group-prepend__item {
    padding: rem(10);
  }
}
