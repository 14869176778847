@charset 'utf-8';

.c-heading {
  font-size: rem(18);
  font-weight: bold;
  border-left: 4px solid $primary;
  padding: rem(5) 0 rem(5) rem(18);
  @include mq(lg) {
    font-size: rem(22);
    border-left-width: 5px;
  }
  &--page {
    padding: rem(30) 0;
    background: $gray;
    margin-bottom: rem(14);
    @include mq(lg) {
      padding: rem(40) 0;
    }
    .c-heading__title {
      font-size: rem(22);
      font-weight: bold;
      @include mq(lg) {
        font-size: rem(28);
      }
    }
  }
  &--secondary {
    font-size: rem(16);
    font-weight: bold;
    border-bottom: 4px solid #F1F1F1;
    padding-bottom: rem(8);
    @include mq(lg) {
      font-size: rem(20);
    }
  }
}
