@charset 'utf-8';

// 旧バージョンスタイルの上書き
.cf:before,
.cf:after {
    content: "";
    display: table;
}

.cf:after {
    clear: both;
}

.cf {
    zoom: 1;
}

.pchidden {
  display: none;
}

.sphidden {
  display: block;
}

.left {
  float: left;
}

.right {
  float: right;
}

.alignleft {
  float: left;
  margin: 0 1em 1em 0;
}

.alignright {
  float: left;
  margin: 0 0 1em 1em;
}

.small {
  font-size: 0.7em;
}

.large {
  font-size: 1.3em;
}

.bold {
  font-weight: bold;
}

.iframeWrap {
  position: relative;
  width: 100%;
  padding-top: 56.25%;

}

.iframeWrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.img-auto img {
  height: auto !important;
}

.next-page-link {
  & > a {
    @extend %c-btn--outline;
    display: block;
  }
}

.page-link {
  @extend .c-pagination;
  & > a {
    @extend %c-pagination__link;
    margin-left: rem(4);
    margin-right: rem(4);
    color: inherit;
    &:hover, &:focus {
      text-decoration: none;
    }
  }
  & > span {
    @extend %c-pagination__link--active;
    margin-left: rem(4);
    margin-right: rem(4);
  }
}

.head-word02 {
  @extend .c-heading;
}
.head-word03 {
  @extend .c-heading--secondary;
}


