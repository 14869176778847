@charset 'utf-8';

.c-tooltip {
  &__btn {
    color: #999;
    @include mq(lg) {
      &:hover, &:focus {
        color: #222;
      }
    }
  }
}
