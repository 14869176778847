$env: "prod";
@charset 'utf-8';

@import './foundation/vars';

// ===============
// node_modules
// ===============
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~ress';
@import '~modaal/dist/css/modaal.css';
@import '~select2';
@import '~tippy.js/dist/tippy.css';
@import '~dropify/dist/css/dropify.css';
@import '~flatpickr/dist/flatpickr.min.css';

// ================
// foundation
// ================
@import './foundation/functions';
@import './foundation/mixins';
@import './foundation/base';
@import './foundation/icon/style';
@import './foundation/overwrite';

// ================
// layout
// ================
@import './layout/root';
@import './layout/header';
@import './layout/container';
@import './layout/footer';
@import './layout/breadcrumbs';

// ================
// object
// ================
// component
@import './object/component/btn';
@import './object/component/overlay';
@import './object/component/article';
@import './object/component/badge';
@import './object/component/list';
@import './object/component/form';
@import './object/component/link';
@import './object/component/heading';
@import './object/component/pagination';
@import './object/component/table';
@import './object/component/alert';
@import './object/component/role';
@import './object/component/event';
@import './object/component/accordion';
@import './object/component/rating';
@import './object/component/user';
@import './object/component/sort';
@import './object/component/tooltip';

// project
@import './object/project/gnav';
@import './object/project/home';
@import './object/project/articles';
@import './object/project/modal';
@import './object/project/article';
@import './object/project/sns';
@import './object/project/profile';
@import './object/project/previous';
@import './object/project/review';
@import './object/project/user';

// utility
@import './object/utility/space';
@import './object/utility/text';
@import './object/utility/flex';

.acf-field-taxonomy {
  .select2-selection.select2-selection--multiple {
    position: relative;
    height: 2rem;
    padding: .625rem;
    height: 40px;
  }
}

@import './custom.css';
@import './custom_d2x.css';
