@charset 'utf-8';

body {
  font-family: "Noto Sans JP", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Sans Emoji";
  color: #222;
  line-height: 1.5;
  &.is-locked {
    height: 100vh;
    overflow-y: hidden;
    position: fixed;
    width: 100%;
  }
}

img,
video,
iframe {
  max-width: 100%;
  vertical-align: bottom;
}

a {
  color: inherit;
  text-decoration: none;
}

ul, ol {
  list-style-position: outside;
  padding-left: 1em;
}
ul {
  list-style-type: disc;
}

iframe {
  line-height: 1;
  vertical-align: bottom;
}
