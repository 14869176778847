@charset 'utf-8';

.c-alert {
  $alert: &;
  padding: rem(14);
  border: 1px solid #ddd;
  margin: rem(20) 0;
  &--gray {
    @extend #{$alert};
    border: 0;
    background: $gray;
  }
}
