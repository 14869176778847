@charset 'utf-8';

.l-breadcrumbs {
  background: linear-gradient(to right, #55001E, #786000);
  &__inner {
    overflow-x: auto;
    margin-left: rem(14);
    padding: rem(10) 0;
  }
  &-list {
    display: flex;
    color: #fff;
    font-size: rem(12);
    white-space: nowrap;
    &__item {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 23em;
      flex: 0 0 auto;
      @include mq(lg) {
        max-width: 43em;
      }
      & + #{&} {
        &::before {
          content: '>';
          opacity: .7;
          margin-left: rem(10);
          margin-right: rem(10);
        }
      }
    }
  }
  &__link {
    opacity: .7;
    display: inline-block;
    @include mq(lg) {
      &:hover, &:focus {
        opacity: 1;
      }
    }
  }
}
