@charset 'utf-8';

.c-btn {
  $this: &;
  display: inline-block;
  background: linear-gradient(45deg, #FF0058, #FFCC00);
  border-radius: rem(3);
  color: #fff;
  font-weight: bold;
  padding: rem(20) rem(16);
  line-height: 1;
  @include mq(lg) {
    &:hover, &:focus {
      background: linear-gradient(45deg, #f10256, #f1c100);
    }
  }
  &.is-block {
    display: block;
    text-align: center;
    width: 100%;
  }
  &--primary {
    @extend #{$this};
    background: $primary;
    @include mq(lg) {
      &:hover, &:focus {
        background: darken($primary, 5%);
      }
    }
  }
  &--secondary {
    @extend #{$this};
    background: $secondary;
    @include mq(lg) {
      &:hover, &:focus {
        background: darken($secondary, 5%);
      }
    }
  }
  &--gray {
    @extend #{$this};
    background: #444;
    @include mq(lg) {
      &:hover, &:focus {
        background: darken(#444, 5%);
      }
    }
  }
  &--outline {
    @extend #{$this};
    border-radius: rem(3);
    color: $secondary;
    background: #fff;
    padding: rem(16);
    font-size: rem(14);
    font-weight: bold;
    border: 1px solid #ddd;
    text-align: center;
    @include mq(lg) {
      font-size: rem(16);
      &:hover, &:focus {
        text-decoration: none !important;
        background: #f5f5f5;
      }
    }
  }
  &--outline-primary {
    @extend #{$this};
    border-radius: rem(3);
    color: $primary;
    background: #fff;
    padding: rem(16);
    font-size: rem(14);
    font-weight: bold;
    border: 1px solid $primary;
    text-align: center;
    @include mq(lg) {
      font-size: rem(16);
      &:hover, &:focus {
        text-decoration: none !important;
        background: $primary;
        color: #fff;
      }
    }
  }
  &--outline-secondary {
    @extend #{$this};
    border-radius: rem(3);
    color: $secondary;
    background: #fff;
    padding: rem(16);
    font-size: rem(14);
    font-weight: bold;
    border: 1px solid $secondary;
    text-align: center;
    @include mq(lg) {
      font-size: rem(16);
      &:hover, &:focus {
        text-decoration: none !important;
        background: $secondary;
        color: #fff;
      }
    }
  }
  &--light {
    @extend #{$this};
    background: $gray;
    color: #222;
    @include mq(lg) {
      &:hover, &:focus {
        background: darken($gray, 5%);
      }
    }
    &.is-active {
      color: #fff;
      background: #222;
      @include mq(lg) {
        &:hover, &:focus {
          background: lighten(#222, 5%);
        }
      }
    }
    &.is-round {
      border-radius: rem(70);
    }
  }
  &--link {
    @extend #{$this};
    background: transparent;
    color: $secondary;
    @include mq(lg) {
      &:hover, &:focus {
        background: #e0e0e0;
      }
    }
  }
  &-sns {
    $sns-btn: &;
    display: flex;
    width: rem(32);
    height: rem(32);
    border-radius: rem(2);
    align-items: center;
    color: #fff;
    justify-content: center;
    font-size: rem(20);
    &.is-lg {
      width: rem(40);
      height: rem(40);
      font-size: rem(24);
      @include mq(lg) {
        width: rem(50);
        height: rem(50);
        font-size: rem(30);
      }
    }
    &.is-block {
      width: 100%;
      height: rem(50);
      font-size: rem(14);
      font-weight: bold;
      @include mq(lg) {
        font-size: rem(16);
      }
      i {
        font-size: rem(20);
        vertical-align: middle;
      }
    }
    &--line {
      @extend #{$sns-btn};
      background: #00B900;
      @include mq(lg) {
        &:hover, &:focus {
          background: darken(#00B900, 5%);
        }
      }
    }
    &--twitter {
      @extend #{$sns-btn};
      background: #55ACEE;
      @include mq(lg) {
        &:hover, &:focus {
          background: darken(#55ACEE, 5%);
        }
      }
    }
    &--facebook {
      @extend #{$sns-btn};
      background: #3B5998;
      @include mq(lg) {
        &:hover, &:focus {
          background: darken(#3B5998, 5%);
        }
      }
    }
    &--instagram {
      @extend #{$sns-btn};
      background: #362250;
      @include mq(lg) {
        &:hover, &:focus {
          background: darken(#362250, 5%);
        }
      }
    }
  }
}
%c-btn--outline {
  @extend .c-btn--outline;
}
