@charset 'utf-8';

.p-modal {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: rem(40) rem(14) 0;
  z-index: 2000;
  visibility: hidden;
  opacity: 0;
  transition: all .3s;
  pointer-events: none;
  line-height: 1.5;
  display: none;
  &.is-show {
    visibility: visible;
    opacity: 1;
    display: block;
  }
  &__header {
    color: #fff;
    font-size: rem(22);
    font-weight: bold;
    text-align: center;
    margin-bottom: rem(40);
  }
  &__body {
    pointer-events: auto;
    width: rem(720);
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
    border-radius: 5px;
    padding: rem(20) rem(14);
    max-height: 55vh;
    overflow-y: auto;
    @include mq(lg) {
      max-height: 68vh;
    }
  }
  &__footer {
    pointer-events: auto;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #444;
    border-top: 1px solid #717171;
    padding: rem(14) rem(14) calc(#{rem(14)} + env(safe-area-inset-bottom));
    transition: all .3s;
  }
  &-links {
    margin-bottom: rem(30);
    &__heading {
      font-size: rem(14);
      font-weight: bold;
      @include mq(lg) {
        font-size: rem(16);
      }
    }
    &-list {
      display: flex;
      flex-flow: row wrap;
      &__item {
        margin-top: rem(10);
        margin-right: rem(10);
        font-size: rem(12);
        font-weight: bold;
        @include mq(lg) {
          font-size: rem(14);
          margin-top: rem(10);
          margin-right: rem(10);
        }
      }
    }
  }
  &-submit {
    width: rem(720);
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
