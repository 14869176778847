@charset 'utf-8';

.c-event {
  $event: &;
  &-header {
    font-size: rem(11);
    position: relative;
    @include mq(lg) {
      font-size: rem(14);
    }
  }
  &__title {
    font-size: rem(15);
    font-weight: bold;
    @include mq(lg) {
      font-size: rem(24);
    }
  }
  &__badge {
    @extend .c-badge--primary;
    font-size: rem(10);
    vertical-align: middle;
    margin-right: rem(6);
    @include mq(lg) {
      font-size: rem(12);
      padding: rem(4) rem(10);
      margin-right: rem(10);
    }
  }
  &-row {
    $row: &;
    margin-top: rem(12);
    @include mq(lg) {
      display: flex;
      &__item {
        flex: 0 0 50%;
      }
    }
    &--date {
      display: flex;
      justify-content: space-between;
      @include mq(lg) {
        flex-flow: row wrap;
      }
      #{$row}__item {
        flex: 0 0 auto;
        @include mq(lg) {
          flex: 0 0 100%;
          & + #{$row}__item {
            margin-top: rem(5);
          }
        }
      }
    }
    &--meta {
      display: flex;
      margin-left: rem(-5);
      margin-right: rem(-5);
      @include mq(lg) {
        flex-flow: row wrap;
      }
      #{$row}__item {
        flex: 0 0 50%;
        padding-left: rem(5);
        padding-right: rem(5);
        @include mq(lg) {
          flex: 0 0 100%;
          & + #{$row}__item {
            margin-top: rem(20);
          }
        }
      }
    }
  }
  &__image {
    width: 100%;
    padding-bottom: 50%;
    display: block;
    position: relative;
    overflow: hidden;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }
  }
  &-thumbs {
    display: flex;
    margin-top: 1px;
    &__item {
      flex: 0 0 33.3333%;
      padding-bottom: 22%;
      position: relative;
      overflow: hidden;
      & + #{&} {
        border-left: 1px solid #fff;
      }
      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
      }
    }
  }
  &-meta {
    font-size: rem(11);
    @include mq(lg) {
      font-size: rem(14);
    }
    &__heading {
      font-weight: bold;
      color: #999;
      font-size: inherit;
    }
  }
  @at-root {
    a#{&} {
      display: block;
      @include mq(lg) {
        &:hover, &:focus {
          text-decoration: none !important;
          opacity: .8;
        }
      }
    }
  }
}
