@charset 'utf-8';

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .u#{$infix}-flex {
      display: flex !important;
    }
    .u-justify-content#{$infix}--center {
      justify-content: center !important;
    }
    .u-justify-content#{$infix}--around {
      justify-content: space-around !important;
    }
    .u-align-items#{$infix}--center {
      align-items: center !important;
    }
    .u-self#{$infix}--center {
      align-self: center !important;
    }
  }
}
