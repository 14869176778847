@charset 'utf-8';

.p-home {
  &-top {
    margin-top: rem(-132);
    position: relative;
    z-index: 1;
    @include mq(lg) {
      margin-top: -44vh;
    }
    &__article {
      .c-article {
        &__title {
          font-size: rem(16);
          @include mq(lg) {
            font-size: rem(24);
          }
        }
        &-author {
          &__icon {
            @include mq(lg) {
              width: rem(28);
              height: rem(28);
            }
          }
          &__name {
            @include mq(lg) {
              font-size: rem(16);
            }
          }
        }
      }
    }
  }
  &-heading {
    display: flex;
    align-items: center;
    margin-bottom: rem(10);
    @include mq(lg) {
      margin-bottom: rem(20);
    }
    &::after {
      content: '';
      flex: 1 1 auto;
      height: 1px;
      background: linear-gradient(to right, $primary, #FFCC00);
    }
    &__title {
      font-size: rem(28);
      font-weight: bold;
      color: $primary;
      margin-right: rem(20);
      line-height: 1.3;
      @include mq(lg) {
        font-size: rem(42);
      }
      & > small {
        font-size: rem(12);
        font-weight: normal;
        display: block;
        @include mq(lg) {
          font-size: rem(16);
        }
      }
    }
  }
  &-pickup {
    margin-top: rem(20);
    @include mq(lg) {
      margin-top: rem(40);
    }
    &-row {
      display: flex;
      flex-flow: row wrap;
      margin-left: rem(-5);
      margin-right: rem(-5);
      &__item {
        flex: 0 0 50%;
        padding-left: rem(5);
        padding-right: rem(5);
        margin-bottom: rem(30);
        @include mq(lg) {
          flex: 0 0 25%;
        }
      }
    }
  }
  &-event {
    background: $gray;
    margin-top: rem(30);
    padding-bottom: rem(40);
    @include mq(lg) {
      padding-bottom: rem(60);
    }
    &-header {
      background: url(../img/home/bg_event_header@2x.png) no-repeat center bottom /cover;
      height: rem(200);
      padding: rem(30) 0;
      &__title {
        color: #fff;
        font-size: rem(28);
        font-weight: bold;
        line-height: 1.3;
        text-align: center;
        @include mq(lg) {
          font-size: rem(42);
        }
        & > small {
          display: block;
          font-weight: normal;
          font-size: rem(12);
          @include mq(lg) {
            font-size: rem(16);
          }
        }
      }
    }
    &-container {
      overflow-x: auto;
      margin-top: rem(-80);
      padding-left: rem(14);
      padding-right: rem(14);
      margin-left: rem(-14);
      margin-right: rem(-14);
      @include mq(lg) {
        overflow-x: initial;
        margin-top: rem(-60);
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
      }
    }
    &-row {
      display: flex;
      margin-left: rem(-5);
      margin-right: rem(-5);
      @include mq(lg) {
        flex-flow: row wrap;
      }
      &__item {
        flex: 0 0 240px;
        padding-left: rem(5);
        padding-right: rem(5);
        @include mq(lg) {
          flex: 0 0 25%;
          margin-bottom: rem(20);
        }
      }
    }
    &-article {
      box-shadow: 0 0 10px rgba(#000, .1);
      background: #fff;
      .c-article__image {
        padding-bottom: 70%;
      }
      &__body {
        padding: rem(10) rem(10) rem(20);
      }
    }
    &__date {
      font-size: rem(13);
      display: flex;
      align-items: center;
    }
    &__badge {
      font-size: rem(12);
      font-weight: normal;
      margin-right: rem(5);
    }
    &__title {
      font-size: rem(15);
      font-weight: bold;
      margin-top: rem(6);
    }
    &__btn {
      margin-top: rem(10);
      @include mq(lg) {
        padding: rem(24);
      }
    }
    .c-article-row {
      left: rem(10);
      bottom: rem(10);
    }
  }
  &-topics {
    margin-top: rem(12);
    padding-bottom: rem(60);
    @include mq(lg) {
      margin-top: rem(30);
    }
    &-article {
      margin-bottom: rem(12);
      padding-bottom: rem(12);
      border-bottom: 1px solid #EAEAEA;
      &-row {
        display: flex;
        flex-flow: row wrap;
        @include mq(lg) {
          flex-flow: row nowrap;
        }
        &__item {
          flex: 0 0 100%;
          @include mq(lg) {
            flex: 1 1 auto;
          }
          &--image {
            flex: 0 0 100%;
            @include mq(lg) {
              flex: 0 0 60%;
              margin-right: rem(20);
            }
          }
        }
      }
      .c-article {
        &__title {
          font-size: rem(16);
          @include mq(lg) {
            font-size: rem(24);
          }
        }
        &-author {
          &__icon {
            @include mq(lg) {
              width: rem(28);
              height: rem(28);
            }
          }
          &__name {
            @include mq(lg) {
              font-size: rem(16);
            }
          }
        }
        &-row {
          position: static;
        }
      }
    }
    &__btn {
      margin-top: rem(8);
      @include mq(lg) {
        padding: rem(24);
        margin-top: rem(20);
      }
    }
  }
}
