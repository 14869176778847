@charset 'utf-8';

.l-header {
  background: linear-gradient(45deg, #FF0058, #FFCC00);
  padding: rem(11) rem(14);
  &.is-home {
    padding-bottom: rem(140);
    @include mq(lg) {
      padding-bottom: 45vh;
    }
  }
  &-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__item {
      line-height: 1;
    }
  }
  &__logo {
    width: 95px;
    height: 28px;
    margin-bottom: rem(7);
    @include mq(lg) {
      width: 140px;
      height: auto;
      margin-left: rem(32);
    }
  }
  &-btn {
    $this: &;
    color: #fff;
    line-height: 1;
    &--menu {
      @extend #{$this};
      font-size: rem(18);
      @include mq(lg) {
        font-size: rem(26);
        display: none;
      }
    }
    &--search {
      @extend #{$this};
      font-size: rem(22);
      @include mq(lg) {
        font-size: rem(28);
      }
    }
  }
}
