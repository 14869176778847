@charset 'utf-8';

.l-root {
  &__item {
    @include mq(lg) {
      padding-left: rem(300);
      max-width: 100%;
    }
  }
}
