@charset 'utf-8';

.c-link {
  color: $secondary;
  @include mq(lg) {
    &:hover, &:focus {
      text-decoration: underline;
    }
  }
  &--underline {
    color: inherit;
    text-decoration: underline;
    @include mq(lg) {
      &:hover, &:focus {
        text-decoration: none;
      }
    }
  }
}
