@charset 'utf-8';

.p-articles {
  $articles: &;
  &__header {
    font-size: rem(13);
    border-bottom: 1px solid #EAEAEA;
    padding-bottom: rem(10);
    margin-bottom: rem(12);
    @include mq(lg) {
      font-size: rem(15);
    }
    &.set-btn {
      position: relative;
      display: flex;
      align-items: center;
    }
  }
  &__item {
    border-bottom: 1px solid #EAEAEA;
    padding-bottom: rem(12);
    margin-bottom: rem(12);
  }
  &-row {
    display: flex;
    &__item {
      flex: 1 1 auto;
      position: relative;
      &--image {
        flex: 0 0 rem(110);
        margin-right: rem(12);
        @include mq(lg) {
          flex: 0 0 rem(220);
          margin-right: rem(20);
        }
      }
      &--user-image {
        flex: 0 0 rem(50);
        margin-right: rem(12);
        @include mq(lg) {
          flex: 0 0 rem(70);
          margin-right: rem(20);
        }
      }
    }
  }
  .c-article {
    padding-bottom: 0;
    &__image {
      padding-bottom: 65%;
      @include mq(lg) {
        padding-bottom: 50%;
      }
    }
    &__title {
      font-size: rem(13);
      margin-top: 0;
      @include mq(lg) {
        font-size: rem(18);
        margin-top: rem(10);
      }
    }
    &-row {
      position: static;
    }
  }
  &--sm {
    display: flex;
    flex-flow: row wrap;
    margin-left: rem(-5);
    margin-right: rem(-5);
    #{$articles}__item {
      flex: 0 0 50%;
      padding-left: rem(5);
      padding-right: rem(5);
      border-bottom: 0;
      @include mq(lg) {
        flex: 0 0 33.3333%;
      }
    }
    &.is-lg-4 {
      #{$articles}__item {
        @include mq(lg) {
          flex: 0 0 25%;
        }
      }
    }
  }
}
