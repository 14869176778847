@charset 'utf-8';

.c-article {
  $article: &;
  padding-bottom: rem(35);
  position: relative;
  height: 100%;
  &__image {
    width: 100%;
    padding-bottom: 50%;
    display: block;
    position: relative;
    overflow: hidden;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }
  }
  &__badge {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    font-size: 0;
  }
  &__title {
    font-size: rem(13);
    font-weight: bold;
    margin-top: rem(10);
    @include mq(lg) {
      font-size: rem(16);
    }
  }
  &-author {
    display: inline-flex;
    align-items: center;
    &__icon {
      width: rem(24);
      height: rem(24);
      border: 1px solid #DBDBDB;
      margin-right: rem(3);
      border-radius: rem(2);
      & > img {
        object-fit: cover;
        vertical-align: top;
      }
    }
    &__name {
      font-size: rem(13);
    }
  }
  &__date {
    color: #666;
    font-size: rem(11);
    @include mq(lg) {
      font-size: rem(13);
    }
  }
  &__client {
    font-size: rem(11);
    color: #888;
    @include mq(lg) {
      font-size: rem(13);
    }
  }
  &-row {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-top: rem(6);
    &__item {
      & + #{&} {
        margin-left: auto;
        @include mq(lg) {
          margin-left: rem(10);
        }
      }
    }
  }
  @at-root {
    a#{&} {
      display: block;
      @include mq(lg) {
        #{$article} {
          &__image {
            & > img {
              transition: transform 0.4s;
            }
          }
        }
        &:hover, &:focus {
          text-decoration: none !important;
          #{$article} {
            &__image {
              & > img {
                opacity: 1;
                transform: scale(1.1);
              }
            }
            &__title,
            &__date,
            &-author,
            &__client {
              opacity: .8;
            }
          }
        }
      }
    }
  }
}
