@charset 'utf-8';

.c-form {
  &-control {
    $this: &;
    outline: none;
    appearance: none;
    font-size: rem(14);
    line-height: normal;
    border-radius: 0;
    @include mq(lg) {
      font-size: rem(16);
    }
    &::placeholder {
      color: #999;
      line-height: normal;
    }
    &[type="text"],
    &[type="email"],
    &[type="tel"],
    &[type="number"],
    &[type="password"],
    &[type="date"],
    &[type="datetime"],
    &[type="datetime-local"],
    &[type="time"] {
      width: 100%;
      border: 1px solid #ddd;
      padding: rem(10);
      background: #fff;
      &:focus {
        background-color: #f5f5f5;
      }
      &:disabled {
        background: #ddd;
      }
    }
    @at-root {
      select#{&}  {
        width: 100%;
        border: 1px solid #ddd;
        padding: rem(10);
        background: #fff;
        &:focus {
          background-color: #f5f5f5;
        }
      }
    }
    @at-root {
      textarea#{&} {
        width: 100%;
        height: rem(130);
        border: 1px solid #ddd;
        padding: rem(10);
        background: #fff;
        &:focus {
          background-color: #f5f5f5;
        }
        &.is-lg {
          height: rem(300);
        }
        &.is-md {
          height: rem(200);
        }
      }
    }
    &.is-invalid {
      border-color: #c00;
    }
    &.is-valid {
      border-color: #40c925;
    }
  }
  &-row {
    display: flex;
    flex-flow: row wrap;
    margin-left: rem(-5);
    margin-right: rem(-5);
    &__item {
      $this: &;
      flex: 1 0 50%;
      padding-left: rem(5);
      padding-right: rem(5);
      @include mq(sm) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      &--lg {
        @extend #{$this};
        flex: 0 0 100%;
        max-width: 100%;
      }
      &--sm {
        @extend #{$this};
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
      }
      &--xs {
        @extend #{$this};
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }
  &-inline {
    display: inline-flex;
  }
  &__label {
    font-size: rem(14);
    font-weight: bold;
    display: inline-block;
    @include mq(lg) {
      font-size: rem(16);
    }
  }
  &-group {
    margin-bottom: rem(30);
    &__heading {
      margin-bottom: rem(10);
      display: flex;
      align-items: center;
    }
  }
  &-list {
    list-style: none;
    padding: 0;
    &__item {
      & + #{&} {
        margin-top: rem(10);
      }
    }
  }
  &-terms {
    background: $gray;
    padding: rem(14);
    overflow-y: auto;
    height: rem(208);
    font-size: rem(12);
    @include mq(lg) {
      font-size: rem(14);
    }
    &__heading {
      font-weight: bold;
      font-size: rem(14);
      margin-bottom: rem(10);
      @include mq(lg) {
        font-size: rem(16);
      }
    }
  }
  &__note {
    font-size: rem(12);
    margin: rem(6) 0 0;
    color: #999;
  }
}

.c-input {
  &-group {
    &-prepend {
      display: flex;
      &__item {
        $this: &;
        background: #fff;
        border: 1px solid #ddd;
        border-right: none;
        display: flex;
        align-items: center;
        padding-left: rem(10);
        padding-right: rem(10);
        flex: 0 0 auto;
      }
      .c-form-control {
        border-left: none;
        flex: 1 1 auto;
        min-width: 0;
      }
    }
    &-append {
      display: flex;
      &__item {
        $this: &;
        background: #fff;
        border: 1px solid #ddd;
        border-left: none;
        display: flex;
        align-items: center;
        padding-left: rem(10);
        padding-right: rem(10);
        flex: 0 0 auto;
      }
      .c-form-control {
        border-right: none;
        padding-right: 0;
        flex: 1 1 auto;
        min-width: 0;
      }
    }
    &__btn {
      color: #6A6A6A;
      @include mq(lg) {
        &:hover, &:focus {
          color: #222;
        }
      }
    }
  }
}

.c-custom-select {
  position: relative;
  &::after {
    content: '';
    display: inline-block;
    width: rem(14);
    height: rem(8);
    background: url(../img/common/icon_arrow_bottom.svg) no-repeat center center /cover;
    position: absolute;
    top: 50%;
    right: rem(14);
    transform: translateY(-50%);
  }
}
.c-custom-checkbox {
  display: block;
  position: relative;
  & > input {
    position: absolute;
    z-index: -1;
    left: 0;
    opacity: 0;
  }
  & > label {
    display: inline-block;
    padding: 0 0 0 rem(32);
    cursor: pointer;
    border-radius: rem(3);
    background: #fff;
    font-size: rem(14);
    @include mq(lg) {
      font-size: rem(16);
      padding: 0 0 0 rem(36);
    }
    &::before {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      border: 1px solid #ddd;
      background: #f3f3f3;
      position: absolute;
      left: 0;
      border-radius: rem(2);
    }
  }
  & > input:checked + label {
    &::after {
      content: $icd-check;
      font-family: $icomoon-font-family;
      display: inline-block;
      position: absolute;
      top: rem(3);
      left: rem(5);
      font-size: rem(14);
      @include mq(lg) {
        top: rem(3);
      }
    }
  }
}

.c-custom-radio {
  @extend .c-custom-checkbox;
  & > label {
    &::before {
      border-radius: 50%;
      border: 2px solid #ddd;
    }
  }
  & > input:checked + label {
    &::before {
      background: #fff;
      border: 2px solid #ddd;
    }
    &::after {
      width: 8px;
      height: 8px;
      background: color('primary');
      border-radius: 50%;
      top: 25px;
      left: 22px;
    }
  }
}
