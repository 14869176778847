@charset 'utf-8';

.c-pagination {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: rem(20);
  font-size: rem(13);
  padding: 0;
  @include mq(lg) {
    font-size: rem(15);
  }
  &__item {
    $this: &;
    padding-left: rem(4);
    padding-right: rem(4);
    &--first {
      @extend #{$this};
      &::after {
        content: '…';
        display: inline-block;
        margin-left: rem(8);
      }
    }
    &--last {
      @extend #{$this};
      &::before {
        content: '…';
        display: inline-block;
        margin-right: rem(8);
      }
    }
  }
  &__link {
    $this: &;
    display: inline-block;
    min-width: rem(40);
    padding: rem(10);
    border: 1px solid #ddd;
    text-align: center;
    border-radius: rem(2);
    background: #fff;
    @include mq(lg) {
      &:hover, &:focus {
        background: #ddd;
      }
    }
    &--active {
      @extend #{$this};
      background: #ddd;
    }
  }
}

%c-pagination__link {
  @extend .c-pagination__link;
}

%c-pagination__link--active {
  @extend .c-pagination__link--active;
}
