@charset 'utf-8';

.p-sns {
  $sns: &;
  display: flex;
  &.is-center {
    justify-content: center;
  }
  &__item {
    & + #{&} {
      margin-left: rem(6);
    }
  }
  &--lg {
    @extend #{$sns};
    flex-flow: row wrap;
    margin-left: rem(-3);
    margin-right: rem(-3);
    #{$sns}__item {
      flex: 0 0 100%;
      padding-left: rem(3);
      padding-right: rem(3);
      margin-bottom: rem(6);
      @include mq(lg) {
        flex: 0 0 50%;
      }
      & + #{$sns}__item {
        margin-left: 0;
      }
    }
  }
}
