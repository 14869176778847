@charset 'utf-8';

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        .u-ml#{$infix}-auto {
          margin-left: auto !important;
        }
        @for $i from 0 through 30 {
            $j: $i * 5;
            .u-m#{$infix}-#{$j} {
                margin: rem($j) !important;
            }
            .u-mt#{$infix}-#{$j} {
                margin-top: rem($j) !important;
            }
            .u-ml#{$infix}-#{$j} {
                margin-left: rem($j) !important;
            }
            .u-mr#{$infix}-#{$j} {
                margin-right: rem($j) !important;
            }
            .u-mb#{$infix}-#{$j} {
                margin-bottom: rem($j) !important;
            }
            .u-my#{$infix}-#{$j} {
                margin-top: rem($j) !important;
                margin-bottom: rem($j) !important;
            }
            .u-mx#{$infix}-#{$j} {
                margin-left: rem($j) !important;
                margin-right: rem($j) !important;
            }
            .u-p#{$infix}-#{$j} {
                padding: rem($j) !important;
            }
            .u-pt#{$infix}-#{$j} {
                padding-top: rem($j) !important;
            }
            .u-pl#{$infix}-#{$j} {
                padding-left: rem($j) !important;
            }
            .u-pr#{$infix}-#{$j} {
                padding-right: rem($j) !important;
            }
            .u-pb#{$infix}-#{$j} {
                padding-bottom: rem($j) !important;
            }
            .u-py#{$infix}-#{$j} {
                padding-top: rem($j) !important;
                padding-bottom: rem($j) !important;
            }
            .u-px#{$infix}-#{$j} {
                padding-left: rem($j) !important;
                padding-right: rem($j) !important;
            }
        }
    }
}
