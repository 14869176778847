@charset 'utf-8';

.c-badge {
  $badge: &;
  display: inline-block;
  font-size: rem(11);
  font-weight: bold;
  background: #aaa;
  color: #fff;
  padding: rem(2) rem(5);
  &--primary {
    @extend #{$badge};
    background: $primary;
  }
  &--primary-outline {
    @extend #{$badge};
    background: #fff;
    border: 1px solid $primary;
    color: $primary;
  }
  &--pr {
    @extend #{$badge};
    background: rgba($primary, .85);
    font-size: rem(12);
    padding: rem(2) rem(10);
  }
  @at-root {
    a#{&} {
      @include mq(lg) {
        &:hover, &:focus{
          opacity: .7;
        }
      }
    }
  }
}
