@charset 'utf-8';

.c-overlay {
  $this: &;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(#000, .8);
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: all .3s;
  &--gnav {
    @extend #{$this};
    z-index: 99;
  }
  &.is-show {
    opacity: 1;
    visibility: visible;
  }
  &__close {
    font-size: rem(20);
    color: #fff;
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
