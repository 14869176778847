@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?jqczv1') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?jqczv1') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?jqczv1##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icd-pencil {
  &:before {
    content: $icd-pencil;
  }
}
.icd-camera {
  &:before {
    content: $icd-camera;
  }
}
.icd-cog {
  &:before {
    content: $icd-cog;
  }
}
.icd-comment {
  &:before {
    content: $icd-comment;
  }
}
.icd-calendar {
  &:before {
    content: $icd-calendar;
  }
}
.icd-search {
  &:before {
    content: $icd-search;
  }
}
.icd-book {
  &:before {
    content: $icd-book;
  }
}
.icd-carrot_down {
  &:before {
    content: $icd-carrot_down;
  }
}
.icd-carrot_left {
  &:before {
    content: $icd-carrot_left;
  }
}
.icd-carrot_right {
  &:before {
    content: $icd-carrot_right;
  }
}
.icd-carrot_up {
  &:before {
    content: $icd-carrot_up;
  }
}
.icd-check {
  &:before {
    content: $icd-check;
  }
}
.icd-check_alt {
  &:before {
    content: $icd-check_alt;
  }
}
.icd-close {
  &:before {
    content: $icd-close;
  }
}
.icd-dews {
  &:before {
    content: $icd-dews;
  }
}
.icd-doc {
  &:before {
    content: $icd-doc;
  }
}
.icd-down {
  &:before {
    content: $icd-down;
  }
}
.icd-edit {
  &:before {
    content: $icd-edit;
  }
}
.icd-edit2 {
  &:before {
    content: $icd-edit2;
  }
}
.icd-facebook {
  &:before {
    content: $icd-facebook;
  }
}
.icd-feed {
  &:before {
    content: $icd-feed;
  }
}
.icd-headphones {
  &:before {
    content: $icd-headphones;
  }
}
.icd-home {
  &:before {
    content: $icd-home;
  }
}
.icd-instagram {
  &:before {
    content: $icd-instagram;
  }
}
.icd-left {
  &:before {
    content: $icd-left;
  }
}
.icd-line {
  &:before {
    content: $icd-line;
  }
}
.icd-menu {
  &:before {
    content: $icd-menu;
  }
}
.icd-meter {
  &:before {
    content: $icd-meter;
  }
}
.icd-newspaper {
  &:before {
    content: $icd-newspaper;
  }
}
.icd-question_circle {
  &:before {
    content: $icd-question_circle;
  }
}
.icd-right {
  &:before {
    content: $icd-right;
  }
}
.icd-run {
  &:before {
    content: $icd-run;
  }
}
.icd-save {
  &:before {
    content: $icd-save;
  }
}
.icd-search_doc {
  &:before {
    content: $icd-search_doc;
  }
}
.icd-sort {
  &:before {
    content: $icd-sort;
  }
}
.icd-star {
  &:before {
    content: $icd-star;
  }
}
.icd-studio {
  &:before {
    content: $icd-studio;
  }
}
.icd-tshirt {
  &:before {
    content: $icd-tshirt;
  }
}
.icd-twitter {
  &:before {
    content: $icd-twitter;
  }
}
.icd-up {
  &:before {
    content: $icd-up;
  }
}
.icd-user {
  &:before {
    content: $icd-user;
  }
}
.icd-youtube {
  &:before {
    content: $icd-youtube;
  }
}

