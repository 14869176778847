@charset 'utf-8';

.c-user {
  $user: &;
  position: relative;
  height: 100%;
  &__image {
    width: rem(50);
    height: rem(50);
    display: block;
    position: relative;
    overflow: hidden;
    border-radius: rem(2);
    border: 1px solid #DBDBDB;
    @include mq(lg) {
      width: rem(70);
      height: rem(70);
    }
    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }
  }
  &__title {
    font-size: rem(16);
    font-weight: bold;
    @include mq(lg) {
      font-size: rem(18);
    }
  }
  &-meta {
    display: inline-flex;
    flex-wrap: wrap;
    &__item {
      margin-right: rem(10);
      display: inline-block;
    }
    &__label {
      font-size: rem(10);
      color: #888;
      @include mq(lg) {
        font-size: rem(13);
      }
    }
    &__num {
      font-size: rem(13);
      @include mq(lg) {
        font-size: rem(15);
      }
    }
  }
  @at-root {
    a#{&} {
      display: block;
      @include mq(lg) {
        #{$user} {
          &__image {
            & > img {
              transition: transform 0.4s;
            }
          }
        }
        &:hover, &:focus {
          opacity: .8;
        }
      }
    }
  }
}
