@charset 'utf-8';

.l-container {
  $container: &;
  padding-left: rem(14);
  padding-right: rem(14);
  @include mq(xl) {
    width: rem(960);
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
