@charset 'utf-8';

.p-gnav {
  position: fixed;
  left: 0;
  top: 0;
  transform: translateX(-100%);
  transition: .3s ease;
  z-index: 100;
  @include mq(lg) {
    transform: none;
  }
  &.is-show {
    transform: translateX(0);
  }
  &-scroll {
    width: 300px;
    overflow-x: hidden;
    background: $gray;
    min-height: 100vh;
    @include mq(lg) {
      box-shadow: 0 0 20px rgba(#000, .1);
    }
  }
  &__inner {
    width: 300px;
    height: 100vh;
    overflow-y: auto;
    @include mq(lg) {
      width: 316px;
      overflow-y: hidden;
      overflow-x: hidden;
      &:hover {
        overflow-y: scroll;
      }
    }
  }
  &-header {
    padding: rem(14);
    display: flex;
    &__item {
      flex: 0 0 50%;
      padding-left: rem(4);
      padding-right: rem(4);
    }
    &__btn {
      padding: rem(13);
      font-size: rem(14);
    }
  }
  &-nav {
    margin-bottom: rem(20);
    &-row {
      display: flex;
      flex-flow: row wrap;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      &__item {
        flex: 1 1 50%;
        &:nth-child(2n) {
          border-left: 1px solid #ddd;
        }
        &--lg {
          flex: 0 0 100%;
          &:not(:first-child) {
            border-top: 1px solid #ddd;
          }
        }
      }
    }
    &__heading {
      font-size: rem(14);
      font-weight: bold;
      padding: 0 rem(14);
      margin-bottom: rem(10);
      & > span {
        font-size: rem(12);
      }
    }
  }
  &-link {
    $this: &;
    background: #fff;
    display: flex;
    align-items: center;
    padding: rem(16) rem(18);
    @include mq(lg) {
      &:hover, &:focus {
        background: #f5f5f5;
      }
    }
    & > i {
      font-size: rem(18);
      margin-right: rem(10);
    }
    #{$this}__text {
      font-size: rem(14);
      font-weight: bold;
    }
    &.is-center {
      justify-content: center;
      text-align: center;
    }
    &--lg {
      @extend #{$this};
      display: block;
      padding: rem(20) rem(10);
      text-align: center;
      & > i {
        font-size: rem(32);
        margin-bottom: rem(10);
      }
      #{$this}__text {
        display: block;
        font-size: rem(14);
        font-weight: bold;
        text-align: center;
        & > span {
          font-size: rem(12);
        }
      }
    }
  }
  &-sns {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: rem(10);
    padding-bottom: rem(20);
    &__item {
      & + #{&} {
        margin-left: rem(20);
      }
    }
    &__link {
      font-size: rem(24);
      color: #A1A1A1;
      @include mq(lg) {
        &:hover, &:focus {
          color: #333;
        }
      }
    }
  }
}
