@charset 'utf-8';

.c-list {
  $list: &;
  &--plane {
    list-style: none;
    padding-left: 0;
    & > li {
      padding: 0;
    }
  }
  &--notes {
    list-style: none;
    padding-left: 0;
    & > li {
      padding-left: 1.5em;
      position: relative;
      margin-top: .5em;
      &::before {
        content: '※';
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
