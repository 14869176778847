@charset 'utf-8';

.c-rating {
  display: flex;
  align-items: center;
  &-image {
    position: relative;
    font-size: rem(14);
    @include mq(lg) {
      font-size: rem(18);
    }
    &__score {
      overflow-x: hidden;
      position: relative;
      z-index: 1;
      &::before {
        content: '★★★★★';
        color: #EF8F00;
      }
    }
    &__bg {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      &::before {
        content: '★★★★★';
        color: #AAAAAA;
      }
    }
  }
  &__score {
    font-size: rem(16);
    font-weight: bold;
    margin-left: rem(8);
    @include mq(lg) {
      font-size: rem(18);
    }
    &.is-hide {
      display: none;
    }
  }
  &-comment {
    margin-left: rem(20);
    display: flex;
    align-items: center;
    & > i {
      font-size: rem(14);
      color: #aaa;
      @include mq(lg) {
        font-size: rem(16);
      }
    }
  }
}
