@charset 'utf-8';

.p-article {
  &-section {
    & > figure {
      margin: rem(14) 0;
      @include mq(lg) {
        margin: rem(24) 0;
      }
    }
    & > p {
      margin: rem(14) 0;
      font-size: rem(15);
      line-height: 1.8;
      @include mq(lg) {
        font-size: rem(16);
        margin: rem(24) 0;
      }
    }
    a {
      @extend .c-link;
    }
  }
  .c-heading {
    margin: rem(40) 0 rem(14);
    @include mq(lg) {
      margin: rem(60) 0 rem(24);
    }
    &--secondary {
      margin: rem(40) 0 rem(14);
      @include mq(lg) {
        margin: rem(50) 0 rem(24);
      }
    }
  }
  &-nav {
    display: flex;
    border-radius: rem(3);
    margin: rem(40) 0;
    &__item {
      flex: 1 1 33.3333%;
      & + #{&} > a {
        border-left: 1px solid rgba(#fff, .5);
      }
      &:first-child > a {
        border-radius: rem(3) 0 0 rem(3);
      }
      &:last-child > a {
        border-radius: 0 rem(3) rem(3) 0;
      }
      & > a {
        display: block;
        padding: rem(20) rem(5);
        text-align: center;
        color: #fff;
        font-size: rem(13);
        font-weight: bold;
        position: relative;
        background: $primary;
        &::after {
          content: $icd-down;
          font-family: $icomoon-font-family;
          position: absolute;
          left: 50%;
          bottom: rem(5);
          font-size: rem(12);
          transform: translateX(-50%);
          color: rgba(#fff, .7);
        }
        @include mq(lg) {
          font-size: rem(15);
          &:hover, &:focus {
            text-decoration: none;
            background: darken($primary, 3%);
          }
        }
      }
    }
  }
  &-heading {
    text-align: center;
    margin: rem(50) 0 0;
    @include mq(lg) {
      margin-bottom: rem(30);
    }
    &__title {
      font-size: rem(24);
      @include mq(lg) {
        font-size: rem(32);
      }
    }
    &__text {
      font-size: rem(14);
      @include mq(lg) {
        font-size: rem(16);
      }
    }
  }
  &-adimage {
    text-align: center;
    margin: rem(20) 0;
    & > img {
      width: 600px;
    }
  }
  .c-article {
    color: inherit;
  }

  // 記事詳細
  &-hero {
    position: relative;
    margin-bottom: rem(20);
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: rem(138);
      background: $gray;
      z-index: -1;
    }
    &__inner {
      padding-top: rem(14);
      img {
        width: 100%;
      }
    }
  }
  &__title {
    font-size: rem(22);
    font-weight: bold;
    margin-bottom: rem(10);
    @include mq(lg) {
      font-size: rem(26);
    }
  }
  &-row {
    display: flex;
    &.is-center {
      align-items: center;
    }
    &__item {
      &:last-child {
        margin-left: auto;
      }
    }
  }
  &__date {
    font-size: rem(15);
  }
  &-tags {
    display: flex;
    flex-flow: row wrap;
    margin: rem(20) rem(-2);
    list-style: none;
    padding: 0;
    &__item {
      padding: rem(2);
      & > a {
        border: 1px solid #ddd;
        display: inline-block;
        padding: rem(6) rem(10);
        font-size: rem(12);
        color: #222;
        border-radius: rem(3);
        @include mq(lg) {
          font-size: rem(13);
          &:hover, &:focus {
            background: #f5f5f5;
          }
        }
      }
    }
  }
  &-author {
    &__heading {
      font-size: rem(11);
      font-weight: bold;
      color: #888;
      margin-bottom: rem(5);
      @include mq(lg) {
        font-size: rem(13);
      }
    }
    &-meta {
      display: flex;
      align-items: center;
      @include mq(lg) {
        &:hover, &:focus {
          opacity: .7;
        }
      }
      &__icon {
        width: rem(36);
        height: rem(36);
        border: 1px solid #ddd;
        border-radius: rem(2);
        margin-right: rem(5);
        @include mq(lg) {
          width: rem(56);
          height: rem(56);
          margin-right: rem(10);
        }
      }
      &__name {
        font-weight: bold;
        font-size: rem(15);
        @include mq(lg) {
          font-size: rem(17);
        }
      }
    }
    &__description {
      font-size: rem(12);
      margin-top: rem(15);
      @include mq(lg) {
        font-size: rem(14);
      }
    }
  }

  // イベント詳細
  &-images {
    margin-left: rem(-14);
    margin-right: rem(-14);
    margin-bottom: rem(20);
    width: 100%;
    overflow-x: auto;
    @include mq(lg) {
      overflow-x: initial;
      margin-left: 0;
      margin-right: 0;
    }
    &__inner {
      margin-left: rem(14);
      display: flex;
      @include mq(lg) {
        margin-left: rem(-5);
        margin-right: rem(-5);
        flex-flow: row wrap;
      }
    }
    &__item {
      flex: 0 0 rem(150);
      @include mq(lg) {
        flex: 0 0 33.3333%;
        padding-left: rem(5);
        padding-right: rem(5);
        padding-bottom: rem(10);
      }
      & + #{&} {
        margin-left: rem(10);
        @include mq(lg) {
          margin-left: 0;
        }
      }
      & > a {
        display: block;
        width: rem(150);
        height: rem(100);
        overflow: hidden;
        @include mq(lg) {
          width: 100%;
          height: rem(200);
          transition: .3s;
          &:hover, &:focus {
            & > img {
              transform: scale(1.1);
            }
          }
        }
        & > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.4s;
        }
      }
    }
  }
  &-form {
    background: $gray;
    margin-left: rem(-14);
    margin-right: rem(-14);
    margin: rem(40) rem(-14) 0;
    padding: rem(14);
    @include mq(lg) {
      margin-left: 0;
      margin-right: 0;
      padding: rem(40) rem(40) rem(40);
    }
    .c-heading {
      margin: 0 0 rem(20);
      @include mq(lg) {
        width: rem(640);
        margin-left: auto;
        margin-right: auto;
      }
    }
    &__inner {
      background: #fff;
      padding: rem(20);
      box-shadow: 0 0 10px rgba(#000, .1);
      @include mq(lg) {
        padding: rem(40);
        width: rem(640);
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  &-fixed-entry {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: rem(14);
    right: rem(14);
    background: $primary;
    color: #fff;
    width: rem(70);
    height: rem(70);
    font-size: rem(11);
    font-weight: bold;
    border-radius: rem(3);
    box-shadow: 0 0 10px rgba(#000, .1);
    z-index: 1;
    @include mq(lg) {
      width: rem(100);
      height: rem(100);
      bottom: rem(24);
      right: rem(24);
      font-size: rem(14);
      &:hover, &:focus {
        background: darken($primary, 5%);
      }
    }
    & > i {
      font-size: rem(30);
      transform: translateX(-5px);
      @include mq(lg) {
        font-size: rem(35);
      }
    }
  }
}
