@charset 'utf-8';

.p-user {
  &-btns {
    display: flex;
    flex-flow: row wrap;
    &__item {
      margin-right: rem(4);
    }
    &__btn {
      font-size: rem(11);
      padding-top: rem(14);
      padding-bottom: rem(14);
      margin-bottom: rem(4);
      @include mq(lg) {
        font-size: rem(15);
      }
    }
  }
}
