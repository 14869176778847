@charset 'utf-8';

.c-sort {
  position: relative;
  &__btn {
    padding: rem(5);
    @include mq(lg) {

    }
    &.is-active {
      background: $gray;
    }
  }
  &-panel {
    padding: rem(5) 0;
    background: #fff;
    box-shadow: 0 0 rem(6) rgba(#000, .2);
    position: absolute;
    right: 0;
    top: 3em;
    z-index: 1;
    display: none;
    &.is-show {
      display: block;
    }
    &__item {
      display: block;
      padding: rem(8) rem(12);
      white-space: nowrap;
      @include mq(lg) {
        padding: rem(10) rem(15);
        &:hover, &:focus {
          background: #eee;
        }
      }
    }
    &-group {
      & + #{&} {
        margin-top: rem(8);
        border-top: 1px solid #ddd;
        padding-top: rem(8);
        @include mq(lg) {
          margin-top: rem(10);
          padding-top: rem(10);
        }
      }
    }
  }
}
