@charset 'utf-8';

.c-table {
  width: 100%;
  border-collapse: collapse;
  th, td {
    font-size: rem(13);
    padding: rem(10);
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    vertical-align: top;
    @include mq(lg) {
      font-size: rem(15);
      padding: rem(16);
    }
  }
  tbody {
    th {
      font-weight: bold;
      background: #F5F5F5;
      width: 30%;
      text-align: left;
    }
  }
}
