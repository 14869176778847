@charset 'utf-8';

.p-profile {
  margin-bottom: rem(40);
  &-hero {
    $hero: &;
    position: relative;
    margin-bottom: rem(42);
    padding-top: rem(20);
    background: center center /cover $gray;
    @include mq(lg) {
      margin-bottom: rem(60);
    }
    &__image {
      width: rem(72);
      height: rem(72);
      border: 1px solid #DBDBDB;
      border-radius: rem(2);
      background: #fff;
      margin: 0 auto;
      transform: translateY(50%);
      @include mq(lg) {
        width: rem(100);
        height: rem(100);
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    &--user {
      @extend #{$hero};
      padding-top: rem(50);
      margin-bottom: rem(60);
      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 50px;
        background: linear-gradient(to bottom, rgba(#000, 0), rgba(#000, .6));
        position: absolute;
        left: 0;
        bottom: 0;
      }
      @include mq(lg) {
        padding-top: rem(100);
      }
      #{$hero}__image {
        width: rem(100);
        height: rem(100);
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    &__edit-link {
      position: absolute;
      right: rem(14);
      bottom: rem(-14);
      transform: translateY(100%);
      font-size: rem(22);
      color: #999999;
      @include mq(lg) {
        font-size: rem(28);
        &:hover, &:focus {
          color: #444;
        }
      }
    }
    &__edit-image-link {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: block;
      @include mq(lg) {
        &:hover, &:focus {
          background: rgba(#fff, .2);
        }
      }
      i {
        position: absolute;
        z-index: 5;
        bottom: 0;
        right: 0;
        color: #777;
        background: rgba(#fff, .8);
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(24);
        height: rem(24);
        font-size: rem(14);
      }
    }
  }
  &-header {
    display: flex;
    margin-top: rem(14);
    &__image {
      flex: 0 0 rem(50);
      height: rem(50);
      background: #fff;
      border-radius: rem(2);
      border: 1px solid #DBDBDB;
      @include mq(lg) {
        flex: 0 0 rem(80);
        height: rem(80);
      }
      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__name {
      font-size: rem(16);
      font-weight: bold;
      @include mq(lg) {
        margin-top: rem(10);
        font-size: rem(20);
      }
    }
    &__link {
      font-size: rem(20);
      color: #999;
      @include mq(lg) {
        font-size: rem(26);
        &:hover, &:focus {
          color: #222;
        }
      }
    }
  }
  &__name {
    font-size: rem(18);
    font-weight: bold;
    @include mq(lg) {
      font-size: rem(24);
    }
  }
  &__description {
    font-size: rem(13);
    margin-top: rem(20);
    @include mq(lg) {
      font-size: rem(15);
    }
  }
  &-container {
    background: $gray;
    padding-bottom: rem(15);
    @include mq(lg) {
      padding-bottom: rem(40);
    }
    .p-articles {
      &__item {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 0;
        & + .p-articles__item {
          margin-top: rem(4);
        }
      }
    }
    .l-container {
      padding-left: 0;
      padding-right: 0;
      @include mq(lg) {
        padding-left: rem(14);
        padding-right: rem(14);
      }
    }
    .c-article {
      background: #fff;
      padding: rem(10);
    }
    .c-event {
      background: #fff;
      &-header {
        padding-left: rem(10);
        padding-right: rem(10);
        padding-top: rem(10);
        @include mq(lg) {
          padding-left: rem(20);
          padding-right: rem(20);
          padding-top: rem(15);
        }
      }
    }
  }
  &-nav {
    display: flex;
    background: #fff;
    margin-bottom: rem(15);
    &__item {
      flex: 1 1 25%;
      & > a {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        padding: rem(14) rem(5);
        font-size: rem(13);
        font-weight: bold;
        position: relative;
        border-bottom: 1px solid transparent;
        @include mq(lg) {
          font-size: rem(15);
          &:hover, &:focus {
            background: #f5f5f5;
          }
        }
        &.is-active {
          border-bottom-color: #222;
        }
      }
    }

  }
  &-review {
    display: flex;
    margin: rem(10) rem(14) rem(30);
    @include mq(lg) {
      margin-left: rem(60);
      margin-right: 0;
    }
    &__image {
      flex: 0 0 rem(36);
      height: rem(36);
      background: #fff;
      border-radius: rem(2);
      border: 1px solid #fff;
      margin-top: rem(5);
      @include mq(lg) {
        flex: 0 0 rem(50);
        height: rem(50);
      }
      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__content {
      flex: 1 1 100%;
      padding-left: rem(20);
    }
    &__inner {
      background: #fff;
      border-radius: rem(10);
      padding: rem(10) rem(14) rem(14);
      position: relative;
      @include mq(lg) {
        padding: rem(15) rem(20) rem(20);
      }
      &::before {
        content: '';
        border-right: 11px solid #fff;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        position: absolute;
        left: 0;
        top: rem(15);
        transform: translateX(-100%);
      }
    }
  }
  &-sticky {
    display: flex;
    position: sticky;
    bottom: 0;
    border-top: 1px solid #ddd;
    z-index: 1;
    box-shadow: 0 -10px 20px rgba(#000, .05);
    &__item {
      flex: 1 1 25%;
    }
    &__link {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: rem(10);
      font-weight: bold;
      padding: rem(16) rem(10);
      background: rgba(#fff, .9);
      @include mq(lg) {
        font-size: rem(14);
        &:hover, &:focus {
          background: #f5f5f5;
        }
      }
      & > i {
        font-size: rem(22);
      }
    }
  }
  &-heading {
    padding: rem(14);
    display: flex;
    align-items: center;
    @include mq(lg) {
      padding: rem(20) 0;
    }
    &__title {
      font-size: rem(13);
      font-weight: bold;
      @include mq(lg) {
        font-size: rem(20);
      }
    }
  }
  &-edit {
    background: #fff;
    &__inner {
      padding: rem(20) rem(14) rem(14);
      @include mq(lg) {
        padding: rem(30);
      }
    }
  }
  &-sticky-action {
    position: fixed;
    z-index: 2;
    right: rem(14);
    bottom: rem(85);
    box-shadow: 0 0 10px rgba(#000, .2);
    &__item {
      &:first-child {
        border-radius: rem(3) rem(3) 0 0;
        overflow: hidden;
      }
      &:last-child {
        border-radius: 0 0 rem(3) rem(3);
        overflow: hidden;
      }
    }
    &__btn {
      $sticky_btn: &;
      width: rem(70);
      height: rem(70);
      font-size: rem(11);
      font-weight: bold;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;
      i {
        font-size: rem(27);
      }
      &--preview {
        @extend #{$sticky_btn};
        background: $primary;
        @include mq(lg) {
          &:hover, &:focus {
            background: darken($primary, 5%);
          }
        }
      }
      &--draft {
        @extend #{$sticky_btn};
        background: #FF851E;
        @include mq(lg) {
          &:hover, &:focus {
            background: darken(#FF851E, 5%);
          }
        }
      }
    }
  }
}
