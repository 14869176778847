@charset 'utf-8';

.p-review {
  &__item {
    & + #{&} {
      margin-top: rem(10);
    }
  }
  &-panel {
    border: 1px solid #ddd;
    border-radius: rem(3);
    &__header {
      background: $gray;
      padding: rem(10) rem(12);
      font-size: rem(13);
      font-weight: bold;
      display: flex;
      align-items: center;
      @include mq(lg) {
        font-size: rem(15);
      }
    }
    &__image {
      width: rem(24);
      height: rem(24);
      background: #fff;
      border: 1px solid #DBDBDB;
      display: inline-block;
      line-height: 1;
      border-radius: rem(2);
      margin-right: rem(6);
      @include mq(lg) {
        width: rem(32);
        height: rem(32);
      }
      & > img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    &__body {
      padding: rem(12);
      @include mq(lg) {
        padding: rem(12) rem(16) rem(16);
      }
    }
    &-row {
      display: flex;
      align-items: center;
    }
    &__date {
      font-size: rem(11);
      color: #666;
      margin-left: auto;
      @include mq(lg) {
        font-size: rem(13);
      }
    }
  }
}
