@charset 'utf-8';

.c-accordion {
  &__btn {
    display: block;
    width: 100%;
    text-align: left;
    font-size: rem(16);
    font-weight: bold;
    border-bottom: 4px solid #F1F1F1;
    padding: 0 rem(20) rem(8) 0;
    position: relative;
    @include mq(lg) {
      &:hover, &:focus {
        color: $secondary;
      }
    }
    &::after {
      content: $icd-carrot_right;
      font-family: $icomoon-font-family;
      position: absolute;
      top: 0;
      right: 0;
      transition: .3s;
    }
    &.is-show {
      &::after {
        transform: rotate(90deg);
      }
    }
  }
  &__body {
    padding-top: rem(14);
    visibility: hidden;
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: .3s linear;
    &.is-show {
      height: auto;
      opacity: 1;
      visibility: visible;
      padding-bottom: rem(20);
    }
  }
}
