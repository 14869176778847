@charset 'utf-8';

.l-footer {
  background: #222;
  color: #A1A1A1;
  padding: rem(24) 0 rem(30);
  &__logo {
    width: 95px;
    margin: 0 auto rem(12);
  }
  &__description {
    font-size: rem(11);
    @include mq(lg) {
      font-size: rem(13);
    }
    p + p {
      margin-top: rem(16);
    }
    a {
      text-decoration: underline;
      @include mq(lg) {
        &:hover, &:focus {
          text-decoration: none;
          color: #fff;
        }
      }
    }
  }
  &-sns {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(30) 0;
    &__item {
      & + #{&} {
        margin-left: rem(20);
      }
    }
    &__link {
      font-size: rem(24);
      color: #A1A1A1;
      @include mq(lg) {
        &:hover, &:focus {
          color: #fff;
        }
      }
    }
  }
  &-nav {
    margin-left: rem(-14);
    margin-right: rem(-14);
    border-top: 1px solid #4B4B4B;
    @include mq(lg) {
      margin-left: 0;
      margin-right: 0;
      display: flex;
      justify-content: center;
      padding: 0 0 rem(24);
      border-top: 0;
      border-bottom: 1px solid #4B4B4B;
    }
    &__item {
      border-bottom: 1px solid #4B4B4B;
      font-size: rem(13);
      @include mq(lg) {
        border-bottom: 0;
        margin-left: rem(10);
        margin-right: rem(10);
      }
      & > a {
        display: block;
        padding: rem(14);
        @include mq(lg) {
          display: inline;
          padding: 0;
          text-decoration: underline;
          &:hover, &:focus {
            text-decoration: none;
            color: #fff;
          }
        }
      }
    }
  }
  &__copyright {
    font-size: rem(11);
    margin-top: rem(30);
    text-align: center;
  }
}
