@charset 'utf-8';

.c-role {
  $role: &;
  font-size: rem(11);
  display: flex;
  align-items: center;
  &.is-center {
    justify-content: center;
  }
  @include mq(lg) {
    font-size: rem(14);
  }
  &::before {
    content: '';
    display: inline-block;
    width: rem(14);
    height: rem(14);
    background: no-repeat center center /rem(14);
    margin-right: rem(5);
    @include mq(lg) {
      width: rem(18);
      height: rem(18);
      background-size: rem(18);
    }
  }
  &--official {
    @extend #{$role};
    color: $secondary;
    &::before {
      background-image: url(../img/common/img_role_official.svg);
    }
  }
  &--editor {
    @extend #{$role};
    color: $primary;
    &::before {
      background-image: url(../img/common/img_role_editor.svg);
    }
  }
}
